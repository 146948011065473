import $ from "jquery"
import icons from "../icons/icons"
import Swiper, {
  Navigation,
  Pagination,
  Scrollbar,
  Thumbs,
  EffectFade,
  Mousewheel,
  EffectCoverflow,
} from "swiper"
import "swiper/swiper-bundle.css"
import "movinwords/dist/movinwords.css"
import Movinwords from "movinwords/dist/movinwords"
import ScrollBooster from "scrollbooster"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import Headroom from "headroom.js"
import SliderBar from "before-after-slider"
import anime from "animejs/lib/anime.es.js"
import { Fancybox } from "@fancyapps/ui"
import "@fancyapps/ui/dist/fancybox.css"

Swiper.use([
  Navigation,
  Pagination,
  Scrollbar,
  Thumbs,
  EffectFade,
  Mousewheel,
  EffectCoverflow,
])
gsap.registerPlugin(ScrollTrigger)
window.onload = function () {
  setTimeout(() => {
    document.querySelector(".preloader").classList.add("hidden")
  }, 500)
}

var app = {
  iconSpriteFn() {
    icons.forEach(iconSpriteFn)
    function iconSpriteFn(item, index) {
      const iconSprite = document.querySelector("#iconSprite")
      if (iconSprite) {
        iconSprite.innerHTML +=
          "<div class='icon-sprite__item'>" +
          "<span class='icon-sprite__number'>" +
          (index + 1) +
          "</span>" +
          "<div class='icon-sprite__preview'>" +
          item.iconSvg +
          "</div>" +
          "<div class='icon-sprite__name'>" +
          item.iconId +
          "</div>" +
          "</div>"
      }

      const icon = document.querySelectorAll(".icon")
      if (icon) {
        Array.prototype.forEach.call(icon, (el) => {
          let dataIconId = el.getAttribute("data-icon-id")
          if (dataIconId == item.iconId) {
            el.innerHTML = item.iconSvg
          }
        })
      }
    }
  },
  uikitFunctions() {
    function uikitSide() {
      $(document).on("scroll", onScroll)
      function onScroll(event) {
        var scrollPos = $(document).scrollTop()
        $(".js-uikit-side-item").each(function () {
          var refElement = $($(this).attr("href"))
          if (refElement.position().top <= scrollPos + 50) {
            var id = $(this).attr("href")

            $(".uikit__item " + id).addClass("active")
            $(".js-uikit-side-item").removeClass("active")
            $(this).addClass("active")
          } else {
            $(this).removeClass("active")
          }
        })
      }
    }

    function uikitSideItem() {
      $(".uikit__item").each(function () {
        var id = this.id
        var uikitSide = $(".uikit__side")
        let uikitSideEl = `<a href="#${id}" class="uikit__side-item js-uikit-side-item"> ${titleCase(
          id
        )
          .split("-")
          .join(" ")}</a>`
        uikitSide.append(uikitSideEl)
      })
      function titleCase(str) {
        var splitStr = str.toLowerCase().split(" ")
        for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
        }
        return splitStr.join(" ")
      }
    }
    function uikitPageDropdown() {
      $(".js-uikit-pages-dropdown").on("click", (e) => {
        $(".front-ui-dropdown").toggleClass("active")
      })
    }
    uikitPageDropdown()
    uikitSide()
    uikitSideItem()
  },
  inputFn() {
    const inputItem = document.querySelectorAll(
      ".input-item input, .input-item textarea"
    )
    inputItem.forEach((e) => {
      e.addEventListener("focusin", () => {
        e.parentNode.classList.add("input-item--focused")
      })
      e.addEventListener("focusout", () => {
        if (e.value == "") {
          e.parentNode.classList.remove("input-item--focused")
        } else {
          e.parentNode.classList.add("input-item--focused")
        }
      })
    })
  },
  showreelLoaded() {
    setTimeout(() => {
      const showreelPlay = document.querySelector(".showreel__play")
      const heroScroll = document.querySelector(".hero__content-scroll")
      const heroBg = document.querySelector(".hero__slider-bg")
      if (showreelPlay) {
        setTimeout(() => {
          showreelPlay.classList.add("loaded")
        }, 1250)
      }
      if (heroScroll) {
        setTimeout(() => {
          heroScroll.classList.add("loaded")
        }, 1250)
      }
      if (heroBg) {
        heroBg.classList.add("loaded")
      }
    }, 750)
  },
  contactMapFn() {
    const image = document.querySelector(".contact-map__image")
    const viewport = document.querySelector(".contact-map__viewport")
    const content = document.querySelector(".contact-map__content")
    if (image) {
      const sb = new ScrollBooster({
        viewport,
        scrollMode: "transform",
      })
      const offsetX = image.scrollWidth - viewport.offsetWidth
      const offsetY = image.scrollHeight - viewport.offsetHeight
      sb.setPosition({
        x: offsetX / 2,
        y: offsetY / 2,
      })
    }
  },
  pageBackgroundFn() {
    const main = document.querySelector("main")

    if (main.classList.contains("detail-page--white")) {
      document.querySelector("header").classList.add("header--white")
      document.querySelector(".subheader").classList.add("subheader--white")
    }
  },
  indexScrollFn() {
    let container = document.querySelector(".index-about")
    if (window.matchMedia("screen and (min-width: 993px)").matches) {
      if (container) {
        let tl = gsap.timeline({
          scrollTrigger: {
            pin: true,
            scrub: 1,
            trigger: container,
            start: "bottom bottom",
            end: () =>
              container.scrollWidth -
              document.documentElement.clientWidth +
              container.offsetWidth,
          },
          invalidateOnRefresh: true,
          defaults: { ease: "none", duration: 1 },
        })
        let containerStart = window.innerHeight - container.offsetHeight
        gsap.timeline({
          scrollTrigger: {
            trigger: ".hero",
            start: "100% " + containerStart,
            end: () =>
              container.scrollWidth -
              document.documentElement.clientWidth +
              container.offsetWidth,
            scrub: false,
            pin: true,
          },
        })

        tl.to(
          ".index-about__item",
          {
            x: () =>
              -(container.scrollWidth - document.documentElement.clientWidth),
          },
          0
        ).from(
          ".index-about__content--show",
          {
            opacity: 0,
            scale: 0.25,
            duration: 0.2,
            stagger: {
              amount: 0.8,
            },
          },
          0
        )
      }
    }
  },
  headroomFn() {
    var header = document.querySelector("header")
    var options = {
      offset: {
        up: 100,
        down: 50,
      },
      tolerance: {
        up: 5,
        down: 0,
      },
      classes: {
        initial: "header",
        pinned: "header--pinned",
        unpinned: "header--unpinned",
        top: "header--top",
        notTop: "header--not-top",
        bottom: "header--bottom",
        notBottom: "header--not-bottom",
        frozen: "header--frozen",
      },
      onPin: function () {},
      onUnpin: function () {},
      onTop: function () {},
      onNotTop: function () {},
      onBottom: function () {},
      onNotBottom: function () {},
    }
    var headroom = new Headroom(header, options)
    headroom.init()
    const headerBtn = document.querySelector(".js-header-btn")
    const menuOverlay = document.querySelector(".header__overlay")
    headerBtn.addEventListener("click", () => {
      headerBtn.classList.toggle("opened")
      header.classList.toggle("header--menu-opened")
      headroom.freeze()
      if (headerBtn.classList.contains("opened")) {
        headroom.freeze()
      } else {
        headroom.unfreeze()
      }
    })
    menuOverlay.addEventListener("click", () => {
      headerBtn.classList.remove("opened")
      header.classList.remove("header--menu-opened")
      headroom.unfreeze()
    })
  },
  movinWordsFn() {
    const mw = new Movinwords({
      el: ".js-text-animation",
      autostart: false,
      delay: "250",
      duration: "1400",
      transition: "slideInBottom",
      offset: "130",
      highlight: {
        classname: "hero__content-text-spacing",
        tag: "span",
        words: ["IN"],
      },
      wordSpacing: "0",
    })
    setTimeout(() => {
      mw.start()
    }, 300)
  },

  cursorFn() {
    if (document.querySelector(".hero__slider")) {
      const highlightCursor = document.querySelector(".js-hero-highlight")
      const highlightCursorDot = document.querySelector(
        ".js-hero-highlight-dot"
      )
      gsap.set(highlightCursor, {
        xPercent: -50,
        yPercent: -50,
      })
      gsap.set(highlightCursorDot, {
        xPercent: -50,
        yPercent: -50,
      })
      document.addEventListener("pointermove", movecursor)
      document.addEventListener("pointermove", movecursorDot)
      function movecursor(e) {
        gsap.to(highlightCursor, {
          duration: false,
          x: e.clientX,
          y: e.clientY,
        })
      }
      function movecursorDot(e) {
        gsap.to(highlightCursorDot, {
          duration: false,
          x: e.clientX,
          y: e.clientY,
        })
      }

      document.querySelector(".hero__slider").onmouseenter = function () {
        highlightCursor.classList.add("active")
        highlightCursorDot.classList.add("active")
      }
      document.querySelector(".hero__slider").onmouseleave = function () {
        highlightCursor.classList.remove("active")
        highlightCursorDot.classList.remove("active")
      }
    }
  },

  heroSliderFn() {
    var heroSliderBg = new Swiper(".js-hero-slider-bg", {
      loop: true,
      watchSlidesProgress: true,
      slidesPerView: 1,
      simulateTouch: false,
      onlyExternal: true,
      noSwiping: true,
      allowTouchMove: false,
      effect: "fade",
      speed: 700,
    })
    var heroSlider = new Swiper(".js-hero-slider", {
      loop: true,
      spaceBetween: 40,
      speed: 700,
      slidesPerView: 2.35,
      slideToClickedSlide: true,
      thumbs: {
        swiper: heroSliderBg,
      },
      breakpoints: {
        320: {
          spaceBetween: 20,
          slidesPerView: 1.1,
        },
        769: {
          spaceBetween: 20,
          slidesPerView: 1.65,
        },
        1201: {
          spaceBetween: 40,
          slidesPerView: 2.35,
        },
      },
    })
  },
  mainSliderFn() {
    const sliderItems = document.querySelectorAll(
      ".js-main-slider .swiper-slide"
    )
    const mainSlider = new Swiper(".js-main-slider", {
      slidesPerView: 1.5,
      spaceBetween: 100,
      speed: 400,
      mousewheel: {
        invert: false,
      },
      pagination: {
        el: ".js-main-slider .swiper-pagination",
        type: "progressbar",
      },
      on: {
        init: function (e, i) {
          sliderItems.forEach((e, i) => {
            $(".js-main-slider .swiper-pagination-bullets").append(
              `<span class="swiper-pagination-bullet"></span>`
            )
          })
          let paginationBullet = document.querySelectorAll(
            ".swiper-pagination-bullet"
          )
          paginationBullet.forEach((el, i) => {
            if (i === e.activeIndex) {
              el.classList.add("active")
            }
            if (i > e.activeIndex) {
              el.classList.remove("active")
            }
          })
        },
        slideChange: function (e) {
          let paginationBullet = document.querySelectorAll(
            ".swiper-pagination-bullet"
          )
          paginationBullet.forEach((el, i) => {
            if (i === e.activeIndex) {
              el.classList.add("active")
            }
            if (i > e.activeIndex) {
              el.classList.remove("active")
            }
          })
        },
      },
      breakpoints: {
        320: {
          slidesPerView: 1.2,
          spaceBetween: 20,
        },
        577: {
          slidesPerView: 1.2,
          spaceBetween: 20,
        },
        769: {
          slidesPerView: 1.5,
          spaceBetween: 50,
        },
        1201: {
          slidesPerView: 1.5,
          spaceBetween: 100,
        },
      },
    })
  },
  planSliderFn() {
    const planSliderThumb = new Swiper(".js-plan-slider-thumb", {
      loop: true,
      slidesPerView: 1,
      watchSlidesProgress: true,
      slideToClickedSlide: true,
      watchSlidesVisibility: true,
      speed: 700,
    })
    const planSlider = new Swiper(".js-plan-slider", {
      slidesPerView: 2.5,
      spaceBetween: 20,
      speed: 400,
      loop: true,
      mousewheel: {
        invert: false,
      },
      navigation: {
        nextEl: ".js-plan-slider-next",
        prevEl: ".js-plan-slider-prev",
      },
      effect: "coverflow",
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 1,
        slideShadows: false,
      },
      thumbs: {
        swiper: planSliderThumb,
      },
      on: {
        init: function (i) {},
        slideChange: function (e) {},
      },
      breakpoints: {
        320: {
          slidesPerView: 1.2,
          spaceBetween: 20,
        },
        577: {
          slidesPerView: 1.2,
          spaceBetween: 20,
        },
        769: {
          slidesPerView: 1.5,
          spaceBetween: 20,
        },
        1201: {
          slidesPerView: 2.5,
          spaceBetween: 20,
        },
      },
    })
  },
  mainTab: function () {
    $("body").on("click", ".js-tab-item", function (e) {
      $(".js-tab-item").removeClass("active")
      $(".js-tab-item.active").removeClass("active")
      $('.js-tab-item[data-filter="' + $(this).data("filter") + '"]').addClass(
        "active"
      )
      var dataFilter = $(this).data("filter")
      $(".filter-tab-item").delay(250).addClass("filtering")
      // $('.js-filter-tab-item.' + dataFilter).removeClass('filtering');
      window.setTimeout(function () {
        $(".filter-tab-item").removeClass("filtering")
        $(".filter-tab-item").addClass("filtered")
        $(".filter-tab-item." + dataFilter).removeClass("filtered")
      }, 250)
      e.preventDefault()
      return false
    })
  },
  imageCompareFn() {
    const imageComparison = document.getElementById("img-compare")
    var height = "400px"
    if (window.matchMedia("screen and (min-width: 993px)").matches) {
      height = "700px"
    }
    if (imageComparison) {
      new SliderBar({
        el: "#img-compare",
        beforeImg: imageComparison.getAttribute("data-beforeImg"),
        afterImg: imageComparison.getAttribute("data-afterImg"),
        width: "100%",
        height: height,
        line: true,
        lineColor: "#9A774F",
      })
    }
  },
  overflowImageAnim() {
    if (window.matchMedia("screen and (min-width: 993px)").matches) {
      if (document.querySelector(".js-overflow-image-trigger")) {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: ".js-overflow-image-trigger",
            start: "top 50%",
            end: "bottom 50%",
            scrub: true,
          },
        })
        if (document.querySelector(".js-overflow-image-bot")) {
          tl.from(".js-overflow-image-bot", { y: -200 }, 0)
        }
        if (document.querySelector(".js-overflow-image-top")) {
          tl.from(".js-overflow-image-top", { y: 200 }, 0)
        }
      }

      if (document.querySelector(".js-content-ltor")) {
        gsap.from(".js-content-ltor", {
          scrollTrigger: {
            trigger: ".js-content-ltor",
            start: "top-=200 50%",
            end: "50% 50%",
          },
          x: "-50%",
          opacity: 0,
        })
      }
      if (document.querySelector(".js-content-rtol")) {
        gsap.from(".js-content-rtol", {
          scrollTrigger: {
            trigger: ".js-content-rtol",
            start: "top-=200 50%",
            end: "50% 50%",
          },
          x: "50%",
          opacity: 0,
        })
      }
    }
  },

  load() {
    console.log("load")
  },
  resized() {
    console.log("resized")
  },

  photoGridIndexFn() {
    var grid = $(".js-max-photo-grid")
    var gridItem = $(".js-max-photo-grid .photo-grid__item")
    var gridLink = grid.data("all-projects-link")
    var gridTxt = grid.data("all-projects-txt")
    var appendMore = `<a href="${gridLink}" class="photo-grid__more js-fade-up-item">
    <div class="photo-grid__img js-photo-grid-more ">
    </div>
    <div class="photo-grid__link">
      <span class="txt txt--raleway txt--px20 txt--font400 c-light-paragraph">${gridTxt}</span>
      <span class="icon icon-font c-secondary"><svg viewBox="0 0 55 14"  xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M48.2071 0.292969L54.9142 7.00008L48.2071 13.7072L46.7929 12.293L51.0858 8.00008H0V6.00008H51.0858L46.7929 1.70718L48.2071 0.292969Z" />
      </svg></span>
    </div>
    </a>`
    if (gridItem.length > 5) {
      gridItem.slice(5).remove()
      grid.append(appendMore)
    }
    gridItem.each(function (i, e) {
      var gridImg = $(this).find(".photo-grid__img img").clone()
      $(".js-photo-grid-more").append(gridImg)
    })
    var _intervalId

    function fadeInLastImg() {
      var backImg = $(".js-photo-grid-more img:first")
      backImg.hide()
      backImg.remove()
      $(".js-photo-grid-more").append(backImg)
      backImg.show()
    }

    _intervalId = setInterval(function () {
      fadeInLastImg()
    }, 500)
  },

  worldMapFn() {
    const worldMap = document.querySelector(".world-map")
    const worldMapPopup = document.createElement("div")
    worldMapPopup.classList.add("world-map__popup")
    worldMapPopup.innerHTML = `<div class="world-map__popup-wrapper">
    <div class="world-map__popup-country txt txt--raleway txt--px16 txt--font400 js-world-map-popup-country">
    </div>
    <div class="world-map__popup-text txt txt--raleway txt--px14 txt--font400 js-world-map-popup-text">
    </div>
    </div>`

    setTimeout(() => {
      if (worldMap) {
        worldMap.appendChild(worldMapPopup)
        let countries = worldMap.querySelectorAll("path[data-country]")
        countries.forEach((element) => {
          let pin = document.createElement("div")
          let pathLeft = $(element).offset().left - 6
          let pathTop = $(element).offset().top - 6
          let pathWidth = element.getBoundingClientRect().width / 2
          let pathHeight = element.getBoundingClientRect().height / 2
          pin.classList.add("world-map__pin")
          pin.style.left = pathLeft + pathWidth + "px"
          pin.style.top = pathTop + pathHeight + "px"
          worldMap.appendChild(pin)
          element.addEventListener("mouseover", () => {
            let dataCountry = element.getAttribute("data-country")
            let dataText = element.getAttribute("data-text")
            document.querySelector(".js-world-map-popup-country").innerHTML =
              dataCountry
            document.querySelector(".js-world-map-popup-text").innerHTML =
              dataText
            pathLeft = $(element).offset().left - 4
            pathTop = $(element).offset().top - 140 - 3
            pathWidth = element.getBoundingClientRect().width / 2
            pathHeight = element.getBoundingClientRect().height / 2
            worldMapPopup.style.left = pathLeft + pathWidth + "px"
            worldMapPopup.style.top = pathTop + pathHeight + "px"
            worldMapPopup.classList.add("active")
          })
          element.addEventListener("mouseleave", () => {
            worldMapPopup.classList.remove("active")
          })
        })
      }
    }, 300)
  },

  aboutHistorySliderFn() {
    const aboutSlider = document.querySelector(".js-about-history-slider")
    if (aboutSlider) {
      const aboutHistorySlider = new Swiper(aboutSlider, {
        slidesPerView: 1.25,
        spaceBetween: 250,
        speed: 610,
        loop: false,
        mousewheel: {
          invert: false,
        },
        breakpoints: {
          320: {
            slidesPerView: 1.1,
            spaceBetween: 20,
          },
          769: {
            slidesPerView: 1.5,
            spaceBetween: 50,
          },
          993: {
            slidesPerView: 1.5,
            spaceBetween: 50,
          },
          1201: {
            slidesPerView: 1.25,
            spaceBetween: 250,
          },
        },
        on: {
          slideNextTransitionStart: function (e) {
            moveTo(currentStep + 1)
          },
          slidePrevTransitionStart: function (e) {
            moveTo(currentStep - 1)
          },
        },
      })
      const svg = document.getElementById("prog-svg")
      const pathEl = document.querySelector("#prog-svg path")
      const totalLength = pathEl.getTotalLength()
      var asd = aboutHistorySlider.slides
      const points = [["", 94]]
      asd.forEach((element, i) => {
        var minVal = 0
        var maxVal = 100
        i = i + 1
        var ee = (maxVal - minVal) / asd.length - 1
        var result = 100 - ee * i
        points.push(["", result])
      })
      points.reverse()
      points.shift()

      function splitPath() {
        const interval = 3
        const toLen = (percentage) => (percentage * totalLength) / 100
        const paths = []
        for (let i = 0; i < points.length; i++) {
          const from = toLen(points[i][1])

          for (let j = i + 1; j < points.length; j++) {
            const to = toLen(points[j][1])
            const segments = []
            for (let k = from; k <= to; k += interval) {
              const { x, y } = pathEl.getPointAtLength(k)
              segments.push([x, y])
            }
            paths.push({
              segments,
              path: `${i}-${j}`,
            })
          }
        }

        paths.forEach((subPath) => {
          const subPathEl = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "path"
          )
          subPathEl.setAttribute(
            "d",
            `M ${subPath.segments.map(([x, y]) => `${x},${y}`).join(" ")}`
          )
          svg.appendChild(subPathEl)
          subPath.el = subPathEl
        })

        return paths
      }

      const subPaths = splitPath()

      const progressCircle = document.querySelector(".prog-circ")
      progressCircle.style.display = "block"

      const animations = subPaths.map((subPath) => {
        const animePath = anime.path(subPath.el)
        return anime({
          targets: progressCircle,
          easing: "easeInOutCirc",
          autoplay: false,
          duration: 1000,
          translateX: animePath("x"),
          translateY: animePath("y"),
          rotate: animePath("angle"),
        })
      })
      animations[0].reset()

      let currentStep = 0

      function moveTo(step) {
        if (step < 0 || step > animations.length) return
        const delta = step - currentStep

        const path =
          delta > 0 ? `${currentStep}-${step}` : `${step}-${currentStep}`
        const animationIndex = subPaths.findIndex(
          (subPath) => subPath.path === path
        )
        const animationToPlay = animations[animationIndex]

        if (delta < 0 && !animationToPlay.reversed) {
          animationToPlay.reverse()
        }
        if (delta > 0 && animationToPlay.reversed) {
          animationToPlay.reverse()
        }
        animationToPlay.reset()
        animationToPlay.play()

        currentStep = step
      }
    }
  },
  slideUpAnimFn() {
    gsap.utils.toArray(".js-fade-up-item").forEach((el) => {
      gsap.from(el, {
        scrollTrigger: {
          trigger: el,
          start: "top-=100% center",
        },
        duration: 1,
        y: "100%",
        opacity: 0,
        ease: "power3.out",
        stagger: {
          amount: 0.2,
        },
      })
    })
  },

  init: function () {
    app.iconSpriteFn()
    app.uikitFunctions()
    app.load()
    app.photoGridIndexFn()
    app.inputFn()
    app.showreelLoaded()
    app.contactMapFn()
    app.headroomFn()
    app.indexScrollFn()
    app.movinWordsFn()
    app.cursorFn()
    app.heroSliderFn()
    app.pageBackgroundFn()
    app.mainSliderFn()
    app.planSliderFn()
    app.mainTab()
    app.imageCompareFn()
    app.worldMapFn()
    app.aboutHistorySliderFn()
    app.overflowImageAnim()
    app.slideUpAnimFn()
  },
}

function docReadied(fn) {
  window.addEventListener("DOMContentLoaded", fn)
}
function docResized(fn) {
  window.addEventListener("resize", fn)
}
docReadied(() => {
  app.init()
})

docResized(() => {
  app.resized()
})

export default { docResized, docReadied }
